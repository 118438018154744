/**
 * @generated SignedSource<<aed89ac56ef46a53d3bb8be257b0e5c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "SONEIUM_MINATO" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useTransaction_transaction$data = {
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly data: string | null;
  readonly destination: {
    readonly value: string;
  } | null;
  readonly source: {
    readonly value: string;
  } | null;
  readonly value: string | null;
  readonly " $fragmentType": "useTransaction_transaction";
};
export type useTransaction_transaction$key = {
  readonly " $data"?: useTransaction_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTransaction_transaction">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTransaction_transaction"
};

(node as any).hash = "a639095b3f9fe758ab3abde76e3f5f53";

export default node;
