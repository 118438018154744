/**
 * @generated SignedSource<<b841f762f169b5415a53cc6f52b6e8dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "SONEIUM_MINATO" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type itemEvents_data$data = {
  readonly assetContract: {
    readonly address: string;
  };
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly relayId: string;
  readonly tokenId: string;
  readonly " $fragmentType": "itemEvents_data";
};
export type itemEvents_data$key = {
  readonly " $data"?: itemEvents_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"itemEvents_data">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "itemEvents_data"
};

(node as any).hash = "5fc9d438756cd863271fa3704ad70c67";

export default node;
