/**
 * @generated SignedSource<<47f02715e0e4acb4a020a47290892ffe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "SONEIUM_MINATO" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type SignAndPostOrderCancelData = {
  clientSignature: string;
  data: OrderCancelInputDataType;
  serverSignature: string;
};
export type OrderCancelInputDataType = {
  message: string;
  payload: string;
};
export type useHandleBlockchainActionsCancelOrdersMutation$variables = {
  input: SignAndPostOrderCancelData;
};
export type useHandleBlockchainActionsCancelOrdersMutation$data = {
  readonly orders: {
    readonly cancel: {
      readonly transaction: {
        readonly blockExplorerLink: string;
        readonly chain: {
          readonly identifier: ChainIdentifier;
        };
        readonly transactionHash: string;
      } | null;
    };
  };
};
export type useHandleBlockchainActionsCancelOrdersMutation = {
  response: useHandleBlockchainActionsCancelOrdersMutation$data;
  variables: useHandleBlockchainActionsCancelOrdersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionHash",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockExplorerLink",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useHandleBlockchainActionsCancelOrdersMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrdersMutationType",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "OrderCancelType",
            "kind": "LinkedField",
            "name": "cancel",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionType",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHandleBlockchainActionsCancelOrdersMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrdersMutationType",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "OrderCancelType",
            "kind": "LinkedField",
            "name": "cancel",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionType",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d54b865167142a244575e9308421a8f4",
    "id": null,
    "metadata": {},
    "name": "useHandleBlockchainActionsCancelOrdersMutation",
    "operationKind": "mutation",
    "text": "mutation useHandleBlockchainActionsCancelOrdersMutation(\n  $input: SignAndPostOrderCancelData!\n) {\n  orders {\n    cancel(input: $input) {\n      transaction {\n        transactionHash\n        blockExplorerLink\n        chain {\n          identifier\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "672a9998e47e4ffefa1aa290845e5959";

export default node;
