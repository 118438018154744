/**
 * @generated SignedSource<<cbc1ddf4e88cbe1cc95cd9e7b2086eb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ensQuery$variables = {
  chain?: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "%future added value" | null;
  name: string;
};
export type ensQuery$data = {
  readonly accountHelpers: {
    readonly isDelegated: boolean;
    readonly resolveEnsName: string | null;
  };
};
export type ensQuery = {
  response: ensQuery$data;
  variables: ensQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chain"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountHelpersType",
    "kind": "LinkedField",
    "name": "accountHelpers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ScalarField",
        "name": "resolveEnsName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "chain",
            "variableName": "chain"
          },
          {
            "kind": "Variable",
            "name": "identifier",
            "variableName": "name"
          }
        ],
        "kind": "ScalarField",
        "name": "isDelegated",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ensQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ensQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3bc11d708fa2497df89a196912c3cc27",
    "id": null,
    "metadata": {},
    "name": "ensQuery",
    "operationKind": "query",
    "text": "query ensQuery(\n  $name: String!\n  $chain: ChainScalar\n) {\n  accountHelpers {\n    resolveEnsName(name: $name)\n    isDelegated(identifier: $name, chain: $chain)\n  }\n}\n"
  }
};
})();

(node as any).hash = "b571e08f520f224440b418420cbc52b4";

export default node;
