/**
 * @generated SignedSource<<be1d3decd95ea717c14c95c32b9702f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkBalanceDropdownQuery$variables = {
  address: string;
  baseCurrencyChain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "%future added value";
  baseCurrencySymbol: string;
  fundsChain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "%future added value";
};
export type NetworkBalanceDropdownQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ManageBalanceAddFunds_data" | "ManageBalanceBridgeFunds_data" | "NetworkTokenBalances_data" | "NetworkTotalFundsUsd_data">;
};
export type NetworkBalanceDropdownQuery = {
  response: NetworkBalanceDropdownQuery$data;
  variables: NetworkBalanceDropdownQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "baseCurrencyChain"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "baseCurrencySymbol"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fundsChain"
},
v4 = {
  "kind": "Variable",
  "name": "address",
  "variableName": "address"
},
v5 = {
  "kind": "Variable",
  "name": "chain",
  "variableName": "fundsChain"
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NetworkBalanceDropdownQuery",
    "selections": [
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "NetworkTotalFundsUsd_data"
      },
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "NetworkTokenBalances_data"
      },
      {
        "args": [
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "baseCurrencyChain",
            "variableName": "baseCurrencyChain"
          },
          {
            "kind": "Variable",
            "name": "baseCurrencySymbol",
            "variableName": "baseCurrencySymbol"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ManageBalanceBridgeFunds_data"
      },
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "ManageBalanceAddFunds_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "NetworkBalanceDropdownQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v4/*: any*/)
        ],
        "concreteType": "WalletType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "funds",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "usdPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chain",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "chain",
                "variableName": "baseCurrencyChain"
              },
              {
                "kind": "Variable",
                "name": "symbol",
                "variableName": "baseCurrencySymbol"
              }
            ],
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "fundsOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetSwapType",
                "kind": "LinkedField",
                "name": "supportedSwaps",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChainType",
                    "kind": "LinkedField",
                    "name": "chain",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "identifier",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "swapType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4cd333ed567694520d0b3cf002518e5",
    "id": null,
    "metadata": {},
    "name": "NetworkBalanceDropdownQuery",
    "operationKind": "query",
    "text": "query NetworkBalanceDropdownQuery(\n  $address: AddressScalar!\n  $fundsChain: ChainScalar!\n  $baseCurrencyChain: ChainScalar!\n  $baseCurrencySymbol: String!\n) {\n  ...NetworkTotalFundsUsd_data_4bk7OP\n  ...NetworkTokenBalances_data_4bk7OP\n  ...ManageBalanceBridgeFunds_data_JRinp\n  ...ManageBalanceAddFunds_data_4bk7OP\n}\n\nfragment ManageBalanceAddFunds_data_4bk7OP on Query {\n  wallet(address: $address) {\n    funds(chain: $fundsChain) {\n      chain\n      symbol\n      id\n    }\n  }\n}\n\nfragment ManageBalanceBridgeFunds_data_JRinp on Query {\n  wallet(address: $address) {\n    fundsOf(symbol: $baseCurrencySymbol, chain: $baseCurrencyChain) {\n      supportedSwaps {\n        symbol\n        chain {\n          identifier\n        }\n        swapType\n      }\n      id\n    }\n  }\n}\n\nfragment NetworkTokenBalances_data_4bk7OP on Query {\n  wallet(address: $address) {\n    funds(chain: $fundsChain) {\n      ...useUsdValueSortedTokenQuantities_walletFunds\n      id\n    }\n  }\n}\n\nfragment NetworkTotalFundsUsdDisplay_funds on WalletFundsType {\n  quantity\n  usdPrice\n  chain\n}\n\nfragment NetworkTotalFundsUsd_data_4bk7OP on Query {\n  wallet(address: $address) {\n    funds(chain: $fundsChain) {\n      ...NetworkTotalFundsUsdDisplay_funds\n      id\n    }\n  }\n}\n\nfragment useUsdValueSortedTokenQuantities_walletFunds on WalletFundsType {\n  quantity\n  usdPrice\n  chain\n  symbol\n}\n"
  }
};
})();

(node as any).hash = "3bf959ca806b8da32fb998a424834393";

export default node;
