/**
 * @generated SignedSource<<625d4d8629bf87b868e21be69c590c58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "SONEIUM_MINATO" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BulkPurchaseModal_orders$data = ReadonlyArray<{
  readonly item: {
    readonly __typename: string;
    readonly chain: {
      readonly identifier: ChainIdentifier;
    };
    readonly relayId: string;
  };
  readonly payment: {
    readonly relayId: string;
    readonly symbol: string;
  };
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"useFulfillingListingsWillReactivateOrders_orders" | "useTotalPrice_orders">;
  readonly " $fragmentType": "BulkPurchaseModal_orders";
}>;
export type BulkPurchaseModal_orders$key = ReadonlyArray<{
  readonly " $data"?: BulkPurchaseModal_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"BulkPurchaseModal_orders">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BulkPurchaseModal_orders",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChainType",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "identifier",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTotalPrice_orders"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFulfillingListingsWillReactivateOrders_orders"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "df1450406e7abcfef2abfbf9f9097fb1";

export default node;
