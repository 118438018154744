"use client"

import React, { useEffect, useState } from "react"
import { Icon, Text, classNames, useToasts } from "@opensea/ui-kit"
import localFont from "next/font/local"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { Z_INDEX } from "@/constants/zIndex"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { osSiweAdapter } from "@/lib/auth/siwe"
import { WALLET_NAME } from "../../../constants/wallet"
import { MAX_BANNER_HEIGHT, useAnnouncementBanner } from "../utils/context"

const gtAmericaMono = localFont({
  preload: true,
  src: "../../../public/fonts/GT-America-Mono-Regular.woff2",
  display: "swap",
  fallback: [
    "ui-monospace",
    "SFMono-Regular",
    "Menlo",
    "Monaco",
    "Consolas",
    "Liberation Mono",
    "Courier New",
    "monospace",
  ],
  variable: "--font-family-mono",
})

export const useShowOs2AnnouncementBanner = () => {
  const connectedAddress = useConnectedAddress()
  const { pathname } = useRouter()
  const [show, setShow] = useState(false)

  useEffect(() => {
    // Never show banner on studio, covers up header.
    if (pathname.startsWith("/studio")) {
      setShow(false)
      return
    }

    if (connectedAddress) {
      osSiweAdapter.getWhitelisted(connectedAddress).then(setShow)
    } else {
      setShow(false)
    }
  }, [connectedAddress, pathname])

  return show
}

export const AlphaAnnouncementBanner = () => {
  const t = useTranslate("home")
  const { setAnnouncementBannerHeight, setIsAnnouncementBannerShown } =
    useAnnouncementBanner()
  const { os2Login, provider } = useWallet()
  const { showInfoMessage } = useToasts()

  useEffect(() => {
    setAnnouncementBannerHeight(MAX_BANNER_HEIGHT)
    setIsAnnouncementBannerShown(true)

    return () => {
      setIsAnnouncementBannerShown(false)
    }
  }, [setAnnouncementBannerHeight, setIsAnnouncementBannerShown])

  const ctaText = t("os2Banner.button", "Upgrade Now")
  const heading = t(
    "os2Banner.title",
    "You've been invited to try the new OpenSea.",
  )
  const toast = t("os2Banner.toast", "Sign message in wallet")

  if (typeof window === "undefined") {
    return null
  }

  return (
    <NetworkUnsupportedGate>
      {({ handleIfNotSupported }) => (
        <header
          className={classNames(
            gtAmericaMono.className,
            "flex w-full items-center justify-center",
          )}
          style={{
            zIndex: Z_INDEX.NAVBAR + 1,
            top: 0,
            position: "relative",
            background:
              "linear-gradient(90deg, #050F44 0%, #000 51%, #050F44 98.5%), linear-gradient(90deg, #0C156D 0%, #1D2DC9 47%, #0F69F2 100%)",
          }}
        >
          <button
            className="flex flex-col items-center gap-3 py-5 text-center font-ibm-plex-mono uppercase text-white md:flex-row"
            onClick={handleIfNotSupported(async () => {
              if (provider?.getName() !== WALLET_NAME.OpenSeaWallet) {
                showInfoMessage(toast)
              }
              await os2Login()
              // Refresh the page so they can be redirected to OS2
              window.location.reload()
            })}
          >
            <Text>{heading}</Text>
            <Text className="underline">
              {ctaText}
              <Icon className="align-middle" size={24} value="chevron_right" />
            </Text>
          </button>
        </header>
      )}
    </NetworkUnsupportedGate>
  )
}
