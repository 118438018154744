import React from "react"
import { UnstyledButton } from "@opensea/ui-kit"
import styled from "styled-components"
import { ArbitrumLogo } from "@/components/svgs/ChainLogos/ArbitrumLogo.react"
import { ArbitrumNovaLogo } from "@/components/svgs/ChainLogos/ArbitrumNovaLogo.react"
import { AvalancheLogo } from "@/components/svgs/ChainLogos/AvalancheLogo.react"
import { BaseLogo } from "@/components/svgs/ChainLogos/BaseLogo.react"
import { BlastLogo } from "@/components/svgs/ChainLogos/BlastLogo.react"
import { BSCLogo } from "@/components/svgs/ChainLogos/BSCLogo.react"
import { EthereumLogo } from "@/components/svgs/ChainLogos/EthereumLogo.react"
import { GunzillaLogo } from "@/components/svgs/ChainLogos/GunzillaLogo.react"
import { KlaytnLogo } from "@/components/svgs/ChainLogos/KlaytnLogo.react"
import { OptimismAltLogo } from "@/components/svgs/ChainLogos/OptimismAltLogo.react"
import { OptimismLogo } from "@/components/svgs/ChainLogos/OptimismLogo.react"
import { PolygonLogo } from "@/components/svgs/ChainLogos/PolygonLogo.react"
import { SeiLogo } from "@/components/svgs/ChainLogos/SeiLogo.react"
import { SolanaLogo } from "@/components/svgs/ChainLogos/SolanaLogo.react"
import { ZoraLogo } from "@/components/svgs/ChainLogos/ZoraLogo.react"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import {
  isPolygon,
  isKlaytn,
  isEthereum,
  isSolana,
  isArbitrum,
  isArbitrumNova,
  isAvalanche,
  isBSC,
  isOptimism,
  isBase,
  isZora,
  isBlast,
  isGunzilla,
  isSei,
  isB3,
  isFlow,
  isApe,
  isSoneium,
  isMonad,
} from "@/lib/helpers/chainUtils"
import { themeVariant } from "@/styles/styleUtils"
import { B3Logo } from "@/components/svgs/EquallySizedChainLogos/B3Logo.react"
import { FlowLogo } from "@/components/svgs/EquallySizedChainLogos/FlowLogo.react"
import { ApeLogo } from "@/components/svgs/EquallySizedChainLogos/ApeLogo.react"
import { SoneiumLogo } from "@/components/svgs/EquallySizedChainLogos/SoneiumLogo.react"
import { MonadLogo } from "@/components/svgs/EquallySizedChainLogos/MonadLogo.react"
export type ChainLogoVariant = "default" | "gray" | "black" | "white"

type ChainLogoProps = {
  chain: ChainIdentifier
  variant: ChainLogoVariant
  width?: number
  useAltLogo?: boolean
}
// NOTE: due to the viewbox of some of the included SVGs, logos may look misaligned
// if used together - consider using getEquallySizedChainLogo in these cases
export const ChainLogo = ({
  chain,
  variant,
  width = 20,
  useAltLogo = false,
}: ChainLogoProps) => {
  if (isPolygon(chain)) {
    return <PolygonLogo fill={variant} width={width} />
  } else if (isKlaytn(chain)) {
    return <KlaytnLogo fill={variant} width={width} />
  } else if (isEthereum(chain)) {
    return <EthereumLogo fill={variant} width={width} />
  } else if (isSolana(chain)) {
    return <SolanaLogo fill={variant} width={width} />
  } else if (isArbitrum(chain)) {
    return <ArbitrumLogo fill={variant} width={width} />
  } else if (isArbitrumNova(chain)) {
    return <ArbitrumNovaLogo fill={variant} width={width} />
  } else if (isAvalanche(chain)) {
    return <AvalancheLogo fill={variant} width={width} />
  } else if (isBSC(chain)) {
    return <BSCLogo fill={variant} width={width} />
  } else if (isOptimism(chain)) {
    if (useAltLogo) {
      return <OptimismAltLogo fill={variant} width={width} />
    }
    return <OptimismLogo fill={variant} width={width} />
  } else if (isBase(chain)) {
    return <BaseLogo fill={variant} width={width} />
  } else if (isZora(chain)) {
    return <ZoraLogo fill={variant} width={width} />
  } else if (isBlast(chain)) {
    return <BlastLogo fill={variant} width={width} />
  } else if (isGunzilla(chain)) {
    return <GunzillaLogo fill={variant} width={width} />
  } else if (isSei(chain)) {
    return <SeiLogo fill={variant} width={width} />
  } else if (isB3(chain)) {
    return <B3Logo fill={variant} width={width} />
  } else if (isFlow(chain)) {
    return <FlowLogo fill={variant} width={width} />
  } else if (isApe(chain)) {
    return <ApeLogo fill={variant} width={width} />
  } else if (isSoneium(chain)) {
    return <SoneiumLogo fill={variant} width={width} />
  } else if (isMonad(chain)) {
    return <MonadLogo fill={variant} width={width} />
  }
  return null
}

type ChainLogoWithTooltipProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "ref" | "type"
> & {
  chain: ChainIdentifier
  color?: ChainLogoVariant
  useAltLogo?: boolean
  width?: number
}

export const ChainLogoWithTooltip = React.memo(function ChainLogoWithTooltip({
  chain,
  color = "gray",
  useAltLogo,
  width,
  ...buttonProps
}: ChainLogoWithTooltipProps) {
  const t = useTranslate("components")
  const { getChainName } = useChains()
  const chainName = getChainName(chain)
  return (
    <Tooltip
      content={t("chainLogo.tooltip", "Chain: {{chainName}}", {
        chainName,
      })}
    >
      <LogoButton
        aria-label={t(
          "chainLogo.label",
          "{{chainName}} logo",
          { chainName },
          { forceString: true },
        )}
        {...buttonProps}
      >
        <ChainLogo
          chain={chain}
          useAltLogo={useAltLogo}
          variant={color}
          width={width}
        />
      </LogoButton>
    </Tooltip>
  )
})

const LogoButton = styled(UnstyledButton)`
  ${({ theme }) =>
    themeVariant({
      variants: {
        dark: {
          "&:hover svg": {
            fill: theme.colors.fog,
          },
        },
        light: {
          "&:hover svg": {
            fill: theme.colors.oil,
          },
        },
      },
    })}
`
