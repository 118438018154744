import React, { useMemo } from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { WagmiProvider as WagmiProviderBase, createConfig, http } from "wagmi"
import { coinbaseWallet } from "wagmi/connectors"
import { OPENSEA_LOGO_IMG } from "@/constants"
import { SITE_URL_STRING } from "@/context/location"
import { chains } from "@/lib/wagmi"

const queryClient = new QueryClient()

type WagmiProviderProps = {
  children: React.ReactNode
}

export function WagmiProvider({ children }: WagmiProviderProps) {
  const config = useMemo(() => {
    // Use 1193 provider managed by internal wallet abstractions for wagmi transport (if available)
    const transports = Object.fromEntries(
      chains.map(chain => {
        const transport = http()
        return [chain.id, transport]
      }),
    )
    return createConfig({
      pollingInterval: 2_000,
      chains,
      transports,
      connectors: [
        coinbaseWallet({
          appName: "OpenSea",
          appLogoUrl: SITE_URL_STRING + OPENSEA_LOGO_IMG,
        }),
      ],
    })
  }, [])

  return (
    <WagmiProviderBase config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProviderBase>
  )
}
