import type { ChainIdentifier } from "@/hooks/useChains/types"
import { reverse } from "@/lib/helpers/object"

export const CHAIN_IDENTIFIER_ENUM_MAPPING: Record<ChainIdentifier, string> = {
  BAOBAB: "baobab",
  BASE: "base",
  BASE_GOERLI: "base_goerli",
  BASE_SEPOLIA: "base_sepolia",
  BLAST: "blast",
  BLAST_SEPOLIA: "blast_sepolia",
  GUNZILLA_TESTNET: "gunzilla_testnet",
  SEI: "sei",
  SEI_DEVNET: "sei_devnet",
  SEI_TESTNET: "sei_testnet",
  BSC: "bsc",
  BSC_TESTNET: "bsc_testnet",
  ETHEREUM: "ethereum",
  KLAYTN: "klaytn",
  MATIC: "matic",
  MUMBAI: "mumbai",
  AMOY: "amoy",
  RINKEBY: "rinkeby",
  GOERLI: "goerli",
  SOLANA: "solana",
  SOLDEV: "soldev",
  ARBITRUM: "arbitrum",
  ARBITRUM_NOVA: "arbitrum_nova",
  ARBITRUM_GOERLI: "arbitrum_goerli",
  ARBITRUM_SEPOLIA: "arbitrum_sepolia",
  AVALANCHE: "avalanche",
  AVALANCHE_FUJI: "avalanche_fuji",
  OPTIMISM: "optimism",
  OPTIMISM_GOERLI: "optimism_goerli",
  OPTIMISM_SEPOLIA: "optimism_sepolia",
  SEPOLIA: "sepolia",
  ZORA: "zora",
  ZORA_TESTNET: "zora_testnet",
  ZORA_SEPOLIA: "zora_sepolia",
  B3_SEPOLIA: "b3_sepolia",
  B3: "b3",
  FLOW_TESTNET: "flow_testnet",
  FLOW: "flow",
  APE_CURTIS: "ape_curtis",
  APE_CHAIN: "ape_chain",
  SONEIUM_MINATO: "soneium_minato",
  MONAD_TESTNET: "monad_testnet",
  // eslint-disable-next-line relay/no-future-added-value
  "%future added value": "",
}

export const CHAIN_IDENTIFIER_REVERSE_ENUM_MAPPING = reverse(
  CHAIN_IDENTIFIER_ENUM_MAPPING,
)
