/**
 * @generated SignedSource<<c3cbd8c1846978702a5b3e1267e0c3f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "SONEIUM_MINATO" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type ClientSignatureStandard = "PERSONAL" | "SOLANA" | "SOLANA_MESSAGE" | "TYPED_DATA_V4" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_create_bulk_order$data = {
  readonly method: {
    readonly chain: {
      readonly identifier: ChainIdentifier;
    };
    readonly clientMessage: string;
    readonly clientSignatureStandard: ClientSignatureStandard;
    readonly orderDatas: string;
    readonly serverSignature: string;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_create_bulk_order";
};
export type useHandleBlockchainActions_create_bulk_order$key = {
  readonly " $data"?: useHandleBlockchainActions_create_bulk_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_create_bulk_order">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_create_bulk_order"
};

(node as any).hash = "4c1f0ac96c58cece87f03f81e7595d36";

export default node;
