/**
 * @generated SignedSource<<894efea9df3764a17b9f29b4bfc45f99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useCreateRequestedTransactionMutation$variables = {
  calldata: string;
  chain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "%future added value";
  fromAddress: string;
  nonce: number;
  toAddress: string;
  value?: string | null;
};
export type useCreateRequestedTransactionMutation$data = {
  readonly userTransaction: {
    readonly request: {
      readonly relayId: string;
    };
  };
};
export type useCreateRequestedTransactionMutation = {
  response: useCreateRequestedTransactionMutation$data;
  variables: useCreateRequestedTransactionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "calldata"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chain"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fromAddress"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nonce"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toAddress"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v6 = [
  {
    "kind": "Variable",
    "name": "calldata",
    "variableName": "calldata"
  },
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "chain"
  },
  {
    "kind": "Variable",
    "name": "fromAddress",
    "variableName": "fromAddress"
  },
  {
    "kind": "Variable",
    "name": "nonce",
    "variableName": "nonce"
  },
  {
    "kind": "Variable",
    "name": "toAddress",
    "variableName": "toAddress"
  },
  {
    "kind": "Variable",
    "name": "value",
    "variableName": "value"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateRequestedTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTransactionMutationType",
        "kind": "LinkedField",
        "name": "userTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "UserTransactionType",
            "kind": "LinkedField",
            "name": "request",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCreateRequestedTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTransactionMutationType",
        "kind": "LinkedField",
        "name": "userTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "UserTransactionType",
            "kind": "LinkedField",
            "name": "request",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "46f27aa386221152d4cdcfb47695946e",
    "id": null,
    "metadata": {},
    "name": "useCreateRequestedTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateRequestedTransactionMutation(\n  $calldata: String!\n  $chain: ChainScalar!\n  $fromAddress: AddressScalar!\n  $toAddress: AddressScalar!\n  $nonce: Int!\n  $value: BigIntScalar\n) {\n  userTransaction {\n    request(nonce: $nonce, chain: $chain, fromAddress: $fromAddress, calldata: $calldata, toAddress: $toAddress, value: $value) {\n      relayId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7c5950447fa504a8be82184182ee0d58";

export default node;
