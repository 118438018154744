var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6ac3106be2d49a55e1b0da6d470143c880166576"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { ExtraErrorData } from "@sentry/integrations"
import * as Sentry from "@sentry/nextjs"
import { getEnvironment } from "@/constants/environment"
import { SENTRY_COMMON_CONFIG } from "sentry.common.config"

const environment = getEnvironment()
const sampleRate = 0.1 // 10% of errors

const BLOCKCHAIN_ACTION_TYPES_TO_SEND = [
  // Creator / Primary drops related actions
  "MintActionType",
  "DropContractDeployActionType",
  "DropMechanicsUpdateActionType",
]

Sentry.init({
  ...SENTRY_COMMON_CONFIG,
  // Getting a lot of errors that are missing stack traces
  // see https://github.com/getsentry/sentry-javascript/issues/2210#issuecomment-529869721
  integrations: [new ExtraErrorData()],
  // Only send replays for errors
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
  sampleRate: 1, // We use beforeSend to selectively sample
  beforeSend: event => {
    const shouldSend = Math.random() < sampleRate
    const isNonErrorObject = event.exception?.values?.[0]?.value?.startsWith(
      "Object captured as exception with keys",
    )

    if (isNonErrorObject) {
      return null
    }

    if (
      // Don't sample in E2E tests. We want all error for debugging purpuses
      environment === "test:e2e" ||
      // Don't sample application_level errors that lead to "Oops" screen
      event.tags?.application_level ||
      // Don't sample certain blockchain actions
      (event.tags?.blockchain_action_type &&
        BLOCKCHAIN_ACTION_TYPES_TO_SEND.includes(
          event.tags.blockchain_action_type as string,
        )) ||
      // otherwise sample at 10%
      shouldSend
    ) {
      return event
    }

    return null
  },
})
