import type { AssetContractTokenStandard } from "../lib/graphql/__generated__/AssetDetails_data.graphql"
import { getEnvironment } from "./environment"

export const PRODUCT_NAME = "OpenSea"

export const GOOGLE_ANALYTICS_ID = "UA-111688253-1"

export const POLLING_INTERVAL = 1000 * 60 // 1 minute
export const ENGLISH_AUCTION_POLLING_TIMEFRAME_IN_MINUTES = 30

export const IS_RECAPTCHA_ENABLED =
  !(getEnvironment() === "test:e2e") && !(process.env.NODE_ENV === "test")

export const GLOBAL_MAX_PAGE_SIZE = 20
export const COLLECTION_MAX_PAGE_SIZE = 32

export const OPENSEA_APPENGINE_IMAGES_URL = "https://lh3.googleusercontent.com"
export const OPENSEA_IMGIX_URL = "https://img.seadn.io"
export const OPENSEA_IMGIX_STAGING_URL = "https://img-staging.openseadn.io"

export const STATIC_ROOT = "https://static.opensea.io"
// Use storage root to help Twitter og:card issues
export const DEFAULT_IMG = `${STATIC_ROOT}/og-images/Metadata-Image.png`
export const VIDEO_BG_IMG = `${STATIC_ROOT}/gas-free/video-background.svg`
export const SEO_MAX_DESC_LENGTH = 230
export const MAX_ADDRESS_LENGTH = 6
export const MAX_TOKEN_INPUT_VALUE = 100000000000000
export const MIN_FEE_PRECISION = 4
export const OPENSEA_TWITTER_HANDLE = "opensea"

export const BITSKI_CLIENT_ID = "db8ab07f-c15a-44c2-a223-ae34568ecc7e"
export const FORTMATIC_API_KEY = "pk_live_55A076E9A0F5E8CE"
export const FORTMATIC_TESTNET_API_KEY = "pk_test_1E42BD3F09A4CD42"

export const COINBASE_LINK = "https://www.coinbase.com/"
export const GEMINI_LINK = "https://www.gemini.com/"
export const KRAKEN_LINK = "https://www.kraken.com/"
export const ETORO_LINK = "https://www.etoro.com/"

export const POLYGON_BRIDGE_LINK = "https://wallet.polygon.technology/"

export const OPENSEA_LOGO_IMG = "/static/images/logos/opensea-logo.svg"
export const OPENSEA_WHITE_LOGO_IMG = "/static/images/logos/opensea-white.svg"
export const OPENSEA_BLACK_LOGO_IMG = "/static/images/logos/opensea-black.svg"
export const MOONPAY_LOGO_IMG = "/static/images/logos/moonpay.svg"
export const YOUTUBE_LOGO_IMG = "/static/images/logos/youtube.png"
export const NO_ASKS_YET_IMG = "/static/images/empty-asks.svg"
export const NO_BIDS_YET_IMG = "/static/images/empty-bids.svg"
export const NO_HISTORY_DATA_IMG = "/static/images/no-history-data.svg"
export const NO_SIMILAR_ITEMS_IMG = "/static/images/no-similar-items.svg"
export const PLACEHOLDER_IMAGE = "/static/images/placeholder.png"

export const BLOG_URL = "https://opensea.io/blog"
export const DOCS_URL = "https://docs.opensea.io"
export const DISCORD_URL = "https://discord.gg/opensea"
export const HELP_CENTER_URL = "https://support.opensea.io/"

export const ERROR_PAGE_HELP_CENTER_URL =
  "https://support.opensea.io/articles/8867097"

export const PLATFORM_STATUS_URL = "https://status.opensea.io"
export const INSTAGRAM_URL = "https://www.instagram.com/opensea/"
export const NEWSLETTER_URL = "https://opensea.io/blog/newsletter/"
export const LEARN_URL = "https://opensea.io/learn"
export const REDDIT_URL = "https://reddit.com/r/opensea"
export const TWITTER_URL = "https://twitter.com/opensea"
export const YOUTUBE_URL = "https://www.youtube.com/c/OpenSeaTV"
export const TIKTOK_URL = "https://www.tiktok.com/@opensea?lang=en"
export const SUPPORT_URL = "https://support.opensea.io/"
export const JOB_URL = "https://jobs.ashbyhq.com/OpenSea"
export const COMMUNITY_STANDARDS_URL = "https://opensea.io/community-standards"
export const PARTNERS_URL = "https://opensea.io/partners"

export const OPEN_SEA_HOST = "opensea.io"

export const COLLECTION_MANAGER_POST_URL =
  "https://support.opensea.io/articles/8867025"

export const ETH_ICON_URL = `${STATIC_ROOT}/general/ETH.svg`

export const COMPANY_ADDRESS =
  "228 Park Avenue South, #22014, New York, NY 10003"
export const COMPANY_PHONE = "(347) 641-9373"
export const TERMS_OF_SERVICE_POSTED_DATE = "April 4, 2023"
export const WALLET_SECURITY_PROVIDER_POSTED_DATE = "April 3, 2023"
export const DEVELOPER_TERMS_PROVIDER_POSTED_DATE = "April 26, 2023"
export const TERMS_OF_SERVICE_URL = "/tos"
export const PRIVACY_POLICY_URL = "/privacy"
export const API_MESSAGE_404 = "Not found"
export const API_MESSAGE_403 = "Unauthorized"
export const API_MESSAGE_400 = "Invalid request"
export const API_MESSAGE_500 = "Internal server error"

export const CATEGORY_SLUGS: Array<string> = [
  "art",
  "domain-names",
  "gaming",
  "memberships",
  "music",
  "pfps",
  "photography",
  "sports-collectibles",
  "virtual-worlds",
]

export type CategorySlug = (typeof CATEGORY_SLUGS)[number]

export const TOKEN_STANDARD_TO_NAMES: Partial<
  Record<AssetContractTokenStandard, string>
> = {
  ERC721: "ERC-721",
  ERC1155: "ERC-1155",
  ERC20: "ERC-20",
  CONSIDERATION: "Consideration",
  ZERO_EX: "0x",
  WYVERN: "Wyvern",
  ENJIN_LEGACY: "Enjin (Legacy)",
  CRYPTOPUNKS: "CryptoPunks",
  CUSTOM: "Custom",
  UNKNOWN: "Unknown",
  ETH_BRIDGE: "Ethereum Bridge",
  METAPLEX: "Metaplex",
  SOLANA: "solana",
  AUCTION_HOUSE: "auction_house",
  SPL: "spl",
  TRANSFER_HELPER: "Transfer Helper",
  SUPERRARE: "SuperRare",
  MAKERSPLACE: "MakersPlace",
  KNOWNORIGIN: "KnownOrigin",
  CRYPTOKITTIES: "CryptoKitties",
  DECENTRALAND: "Decentraland",
  SEADROP: "SeaDrop",
  "%future added value": "",
}

export const EMPTY_PRICE_DISPLAY = "--"
export const STATS_EMPTY_PRICE_DISPLAY = "—"
export const STATS_POSITIVE_PRICE_DISPLAY = "+"

export const MAX_MEDIA_SIZE_MB = 100

// We don't want to enforce this in our E2E tests, but we do want to enforce it in production.
export const MINIMUM_LISTING_DURATION_IN_MINUTES =
  getEnvironment() === "test:e2e" ? 0 : 15
export const TIME_WINDOW_IN_MINUTES = 3
export const MAXIMUM_DURATION_IN_MONTHS = 6

export type IsIpRightsTakedownDelistedMode =
  | "item"
  | "item-owner"
  | "collection"
  | "collection-owner"

// See `enum ChainIdentifier` in schema.graphql for chain values
export const CREATOR_EXCLUDED_CHAINS = [
  "all",
  "SOLANA",
  "SOLDEV",
  "BSC",
  "BSC_TESTNET",
  // Hide GUNZILLA chains since only whitelisted deployers are allowed on them
  "GUNZILLA",
  "GUNZILLA_TESTNET",
]

// See `enum ChainIdentifier` in schema.graphql for chain values
export const GATED_CHAINS = new Set<string>([
  "flow_testnet",
  "ape_curtis",
  "flow",
  "ape_chain",
  "soneium_minato",
  "monad_testnet",
])
