
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { forceNextTranslateGetInitialProps, getAppInitialProps, } from "@/components/app/initialProps";
import { HomePage, homePageQuery } from "@/features/home";
HomePage.getInitialProps = async (context) => {
    const props = {
        query: homePageQuery,
        cachePolicy: {
            maxAge: 300,
            revalidate: 360
        }
    };
    console.log("Index props", props);
    return getAppInitialProps(context, props);
};
const __Next_Translate__Page__1949c2a5ce3__ = forceNextTranslateGetInitialProps(HomePage);

    export default __appWithI18n(__Next_Translate__Page__1949c2a5ce3__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  