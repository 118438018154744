import { merge } from "lodash"
import {
  arbitrum,
  arbitrumGoerli,
  arbitrumNova,
  arbitrumSepolia,
  avalanche,
  avalancheFuji,
  base,
  baseGoerli,
  baseSepolia,
  blast,
  blastSepolia,
  bsc,
  bscTestnet,
  klaytn,
  klaytnBaobab,
  mainnet,
  optimism,
  optimismGoerli,
  optimismSepolia,
  polygon,
  polygonAmoy,
  polygonMumbai,
  seiDevnet,
  sepolia,
  zora,
  zoraSepolia,
  zoraTestnet,
  b3Sepolia,
  seiTestnet,
  b3,
  flowTestnet,
  curtis,
  apeChain,
  sei,
  flowMainnet,
  soneiumMinato,
} from "viem/chains"
import { IS_PRODUCTION } from "@/constants/environment"

const GUNZILLA_TESTNET = {
  id: 49_321,
  name: "GUNZ Testnet",
  nativeCurrency: { name: "GUN", symbol: "GUN", decimals: 18 },
  rpcUrls: {
    default: {
      http: [
        "https://rpc.gunz.dev/ext/bc/ryk9vkvNuKtewME2PeCgybo9sdWXGmCkBrrx4VPuZPdVdAak8/rpc",
      ],
    },
  },
  blockExplorers: {
    default: {
      name: "Gunzscan",
      url: "https://testnet.gunzscan.io/",
    },
  },
  testnet: true,
}

const MAINNET = IS_PRODUCTION
  ? merge(mainnet, {
      rpcUrls: {
        default: {
          http: [
            "https://eth-mainnet.g.alchemy.com/v2/HudOTsVtxAEzDGEfAeZE128Cx49chBco",
          ],
        },
      },
    })
  : mainnet

const MONAD_TESTNET = {
  id: 10143,
  name: "Monad Testnet",
  nativeCurrency: { name: "MON", symbol: "MON", decimals: 18 },
  rpcUrls: {
    default: {
      // TODO switch to public
      http: [
        "https://monad-testnet.g.alchemy.com/v2/d3HKIjBXx3HMTnPWU9Ftv7cW8LL2BtWr",
      ],
    },
  },
  blockExplorers: {
    default: {
      name: "Monadexplorer",
      url: "https://testnet.monadexplorer.com",
    },
  },
  testnet: true,
}
export const chains = [
  // Mainnet
  MAINNET,
  polygon,
  klaytn,
  bsc,
  arbitrum,
  avalanche,
  optimism,
  arbitrumNova,
  base,
  zora,
  blast,
  b3,
  sei,
  flowMainnet,
  apeChain,
  // Testnet
  sepolia,
  polygonMumbai,
  polygonAmoy,
  klaytnBaobab,
  bscTestnet,
  avalancheFuji,
  arbitrumGoerli,
  arbitrumSepolia,
  optimismGoerli,
  optimismSepolia,
  baseGoerli,
  baseSepolia,
  zoraTestnet,
  zoraSepolia,
  blastSepolia,
  seiDevnet,
  seiTestnet,
  b3Sepolia,
  GUNZILLA_TESTNET,
  flowTestnet,
  curtis,
  soneiumMinato,
  MONAD_TESTNET,
] as const
