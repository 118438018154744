/**
 * @generated SignedSource<<33a8c61a4a799f0b80b5631be8829842>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionWatchlistSort = "CREATED_DATE" | "FLOOR_PRICE" | "NUM_OWNERS" | "ONE_DAY_CHANGE" | "SEVEN_DAY_CHANGE" | "SEVEN_DAY_VOLUME" | "TOTAL_SUPPLY" | "%future added value";
export type StatsTimeWindow = "ALL_TIME" | "FIVE_MIN" | "ONE_DAY" | "ONE_HOUR" | "SEVEN_DAY" | "SIX_HOUR" | "THIRTY_DAY" | "%future added value";
export type RankingsPageWatchlistPaginationQuery$variables = {
  categories?: ReadonlyArray<string> | null;
  chains?: ReadonlyArray<| "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "%future added value"> | null;
  count: number;
  cursor?: string | null;
  floorPricePercentChange: boolean;
  identity: string;
  sortAscending?: boolean | null;
  sortBy?: CollectionWatchlistSort | null;
  timeWindow?: StatsTimeWindow | null;
};
export type RankingsPageWatchlistPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RankingsPageWatchlist_data">;
};
export type RankingsPageWatchlistPaginationQuery = {
  response: RankingsPageWatchlistPaginationQuery$data;
  variables: RankingsPageWatchlistPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categories"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "chains"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "floorPricePercentChange"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "identity"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortAscending"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "timeWindow"
  }
],
v1 = {
  "kind": "Variable",
  "name": "categories",
  "variableName": "categories"
},
v2 = {
  "kind": "Variable",
  "name": "chains",
  "variableName": "chains"
},
v3 = {
  "kind": "Variable",
  "name": "sortAscending",
  "variableName": "sortAscending"
},
v4 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "kind": "Variable",
    "name": "statsTimeWindow",
    "variableName": "timeWindow"
  }
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "eth",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RankingsPageWatchlistPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          {
            "kind": "Variable",
            "name": "floorPricePercentChange",
            "variableName": "floorPricePercentChange"
          },
          {
            "kind": "Variable",
            "name": "identity",
            "variableName": "identity"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "timeWindow",
            "variableName": "timeWindow"
          }
        ],
        "kind": "FragmentSpread",
        "name": "RankingsPageWatchlist_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RankingsPageWatchlistPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "identity"
          }
        ],
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "CollectionTypeConnection",
                "kind": "LinkedField",
                "name": "collectionWatchlist",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CollectionType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "logo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isVerified",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "relayId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pinnedDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCategory",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isWatching",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionStatsV2Type",
                            "kind": "LinkedField",
                            "name": "statsV2",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalQuantity",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v6/*: any*/),
                            "concreteType": "WindowCollectionStatsType",
                            "kind": "LinkedField",
                            "name": "windowCollectionStats",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PriceType",
                                "kind": "LinkedField",
                                "name": "floorPrice",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "numOwners",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalSupply",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalListed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "numOfSales",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "volumeChange",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PriceType",
                                "kind": "LinkedField",
                                "name": "volume",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "condition": "floorPricePercentChange",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": (v6/*: any*/),
                                "kind": "ScalarField",
                                "name": "floorPricePercentChange",
                                "storageKey": null
                              }
                            ]
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [
                  "sortBy",
                  "sortAscending",
                  "chains",
                  "categories"
                ],
                "handle": "connection",
                "key": "RankingsPageWatchlist_collectionWatchlist",
                "kind": "LinkedHandle",
                "name": "collectionWatchlist"
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da4d40101e26bec889d86f9ff1926394",
    "id": null,
    "metadata": {},
    "name": "RankingsPageWatchlistPaginationQuery",
    "operationKind": "query",
    "text": "query RankingsPageWatchlistPaginationQuery(\n  $categories: [CategoryV2Slug!]\n  $chains: [ChainScalar!]\n  $count: Int!\n  $cursor: String\n  $floorPricePercentChange: Boolean!\n  $identity: AddressScalar!\n  $sortAscending: Boolean\n  $sortBy: CollectionWatchlistSort\n  $timeWindow: StatsTimeWindow\n) {\n  ...RankingsPageWatchlist_data_2Hd35i\n}\n\nfragment RankingsPageWatchlist_data_2Hd35i on Query {\n  getAccount(address: $identity) {\n    user {\n      collectionWatchlist(after: $cursor, first: $count, sortBy: $sortBy, sortAscending: $sortAscending, chains: $chains, categories: $categories) {\n        edges {\n          node {\n            createdDate\n            name\n            slug\n            logo\n            isVerified\n            relayId\n            pinnedDate\n            ...StatsCollectionCell_collection\n            ...collection_url\n            ...StatsWatchlistButton_data\n            statsV2 {\n              totalQuantity\n            }\n            windowCollectionStats(statsTimeWindow: $timeWindow) {\n              floorPrice {\n                unit\n                eth\n                symbol\n              }\n              numOwners\n              totalSupply\n              totalListed\n              numOfSales\n              volumeChange\n              volume {\n                unit\n                eth\n                symbol\n              }\n            }\n            floorPricePercentChange(statsTimeWindow: $timeWindow) @include(if: $floorPricePercentChange)\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment StatsCollectionCell_collection on CollectionType {\n  name\n  logo\n  isVerified\n  slug\n}\n\nfragment StatsWatchlistButton_data on CollectionType {\n  isWatching\n  pinnedDate\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n"
  }
};
})();

(node as any).hash = "e133adb961b6d98f458a5a4d9e4849aa";

export default node;
