import { ChainIdentifier } from "@/hooks/useChains/types"
import { CHAIN_IDENTIFIER_ENUM_MAPPING } from "../../constants/chains"
import { IS_TESTNET } from "../../constants/testnet"
import { isValidAddress as isSolanaAddress } from "./address/solana"

export const isMultichain = (chain: ChainIdentifier | undefined): boolean => {
  return chain !== undefined && !isEthereum(chain)
}

export const isEthereum = (
  chain: ChainIdentifier | null | undefined,
): chain is "ETHEREUM" | "RINKEBY" | "GOERLI" | "SEPOLIA" => {
  return (
    chain === "ETHEREUM" ||
    chain === "RINKEBY" ||
    chain == "GOERLI" ||
    chain == "SEPOLIA"
  )
}

export const isPolygon = (
  chain: ChainIdentifier | null | undefined,
): chain is "MATIC" | "MUMBAI" | "AMOY" => {
  return chain === "MATIC" || chain === "MUMBAI" || chain === "AMOY"
}

export const isSolana = (
  chain: ChainIdentifier | null | undefined,
): chain is "SOLANA" | "SOLDEV" => {
  return chain === "SOLANA" || chain === "SOLDEV"
}

export const isKlaytn = (
  chain: ChainIdentifier | null | undefined,
): chain is "KLAYTN" | "BAOBAB" => {
  return chain === "KLAYTN" || chain === "BAOBAB"
}

export const isArbitrum = (
  chain: ChainIdentifier | null | undefined,
): chain is "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" => {
  return (
    chain === "ARBITRUM" ||
    chain === "ARBITRUM_GOERLI" ||
    chain === "ARBITRUM_SEPOLIA"
  )
}

export const isBlast = (
  chain: ChainIdentifier | null | undefined,
): chain is "BLAST" | "BLAST_SEPOLIA" => {
  return chain === "BLAST" || chain === "BLAST_SEPOLIA"
}

export const isGunzilla = (
  chain: ChainIdentifier | null | undefined,
): chain is "GUNZILLA_TESTNET" => {
  return chain === "GUNZILLA_TESTNET"
}

export const isSei = (
  chain: ChainIdentifier | null | undefined,
): chain is "SEI" | "SEI_DEVNET" | "SEI_TESTNET" => {
  return chain === "SEI" || chain === "SEI_DEVNET" || chain === "SEI_TESTNET"
}

export const isArbitrumNova = (
  chain: ChainIdentifier | null | undefined,
): chain is "ARBITRUM_NOVA" => {
  return chain === "ARBITRUM_NOVA"
}

export const isOptimism = (
  chain: ChainIdentifier | null | undefined,
): chain is "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" => {
  return (
    chain === "OPTIMISM" ||
    chain === "OPTIMISM_GOERLI" ||
    chain === "OPTIMISM_SEPOLIA"
  )
}

export const isAvalanche = (
  chain: ChainIdentifier | null | undefined,
): chain is "AVALANCHE" | "AVALANCHE_FUJI" => {
  return chain === "AVALANCHE" || chain === "AVALANCHE_FUJI"
}

export const isBSC = (
  chain: ChainIdentifier | null | undefined,
): chain is "BSC" | "BSC_TESTNET" => {
  return chain === "BSC" || chain === "BSC_TESTNET"
}

export const isBase = (
  chain: ChainIdentifier | null | undefined,
): chain is "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" => {
  return chain === "BASE" || chain === "BASE_GOERLI" || chain === "BASE_SEPOLIA"
}

export const isZora = (
  chain: ChainIdentifier | null | undefined,
): chain is "ZORA" | "ZORA_TESTNET" => {
  return (
    chain === "ZORA" || chain === "ZORA_TESTNET" || chain === "ZORA_SEPOLIA"
  )
}

export const isB3 = (
  chain: ChainIdentifier | null | undefined,
): chain is "B3_SEPOLIA" | "B3" => {
  return chain === "B3_SEPOLIA" || chain === "B3"
}

export const isFlow = (
  chain: ChainIdentifier | null | undefined,
): chain is "FLOW_TESTNET" | "FLOW" => {
  return chain === "FLOW_TESTNET" || chain === "FLOW"
}

export const isApe = (
  chain: ChainIdentifier | null | undefined,
): chain is "APE_CURTIS" | "APE_CHAIN" => {
  return chain === "APE_CURTIS" || chain === "APE_CHAIN"
}

export const isMonad = (
  chain: ChainIdentifier | null | undefined,
): chain is "MONAD_TESTNET" => {
  return chain === "MONAD_TESTNET"
}

export const isSoneium = (
  chain: ChainIdentifier | null | undefined,
): chain is "SONEIUM_MINATO" => {
  return chain === "SONEIUM_MINATO"
}

export const getChainSlug = (chain: ChainIdentifier) => {
  return CHAIN_IDENTIFIER_ENUM_MAPPING[chain]
}

export const getEthereumChain = (): "SEPOLIA" | "ETHEREUM" => {
  return IS_TESTNET ? "SEPOLIA" : "ETHEREUM"
}

export const getPolygonChain = (): "AMOY" | "MATIC" => {
  return IS_TESTNET ? "AMOY" : "MATIC"
}

export const getOptimismChain = (): "OPTIMISM_SEPOLIA" | "OPTIMISM" => {
  return IS_TESTNET ? "OPTIMISM_SEPOLIA" : "OPTIMISM"
}

export const getArbitrumChain = (): "ARBITRUM_SEPOLIA" | "ARBITRUM" => {
  return IS_TESTNET ? "ARBITRUM_SEPOLIA" : "ARBITRUM"
}

export const getBlastChain = (): "BLAST_SEPOLIA" | "BLAST" => {
  return IS_TESTNET ? "BLAST_SEPOLIA" : "BLAST"
}

export const getGunzillaChain = (): "GUNZILLA_TESTNET" | "GUNZILLA" => {
  return IS_TESTNET ? "GUNZILLA_TESTNET" : "GUNZILLA"
}

export const getSeiChain = (): "SEI_TESTNET" | "SEI" => {
  return IS_TESTNET ? "SEI_TESTNET" : "SEI"
}

export const getBaseL2Chain = (): "BASE" | "BASE_SEPOLIA" => {
  return IS_TESTNET ? "BASE_SEPOLIA" : "BASE"
}

export const getSolanaChain = (): "SOLANA" | "SOLDEV" => {
  return IS_TESTNET ? "SOLDEV" : "SOLANA"
}

export const getDefaultChainForAddress = (
  address: string | undefined | null,
) => {
  if (address && isSolanaAddress(address)) {
    return getSolanaChain()
  }
  return getEthereumChain()
}

export const hasBiddingBalance = (chain: ChainIdentifier | undefined) => {
  return isSolana(chain)
}

export const SOLANA_ESCROW_TOKEN_SYMBOL = "LSOL"

export const getSymbolDisplay = (symbol: string) => {
  return symbol === SOLANA_ESCROW_TOKEN_SYMBOL ? "SOL" : symbol
}

export const areCreatorEarningsSupported = (chain: ChainIdentifier) => {
  return !isSolana(chain)
}

export const SOLANA_TOKEN_PROGRAM_ID =
  "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"

export const isCanonicalChainIdentifier = (chain?: ChainIdentifier) => {
  return isEthereum(chain) || isSolana(chain)
}
